import queryString from 'query-string';

export const onClientEntry = () => {
  const parseUrl = (params) => {
    const queryParams = queryString.parse(params);
    
    let { utm_source, utm_medium, li_fat_id } = queryParams;

    if (li_fat_id) {
      li_fat_id = 'Linked In - Social';
    }

    if (utm_source) {
      utm_source = utm_source
        .split('_')
        .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }

    if (utm_medium) {
      utm_medium = utm_medium
        .split('_')
        .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }

    return utm_source || li_fat_id || document.referrer;
  };

  const referringUrl = JSON.parse(window.localStorage.getItem('referringUrlHeadacheStudy'))?.data;

  if (!referringUrl) {
    window.localStorage.setItem(
      'referringUrlHeadacheStudy',
      JSON.stringify({
        data: parseUrl(window.location.search) || 'Direct Navigation'
      })
    );
  }
};